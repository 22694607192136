html {
  scrollbar-gutter: stable;
  min-width: 320px;
  color: #191c1f;
  background-color: #f7f7f7;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, Arimo, sans-serif;
  font-size: 16px;
  line-height: 1rem;
}

body {
  min-height: 100vh;
  background-color: #f7f7f7;
  margin: 0;
  position: relative;
}

.app {
  min-height: calc(100% - 326px);
  justify-content: center;
  display: flex;
}

footer {
  min-height: 100px;
  width: 100%;
  background-color: #d4d4d4;
  margin-top: 50px;
  padding-top: 20px;
  font-size: 14px;
  position: relative;
  bottom: 0;
}

.footer-copirigt {
  color: #fff;
  background-color: #016193;
  padding: 20px;
  font-size: 12px;
  font-weight: 300;
  bottom: 0;
}

.footer-container {
  grid-gap: 1rem;
  min-height: 160px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  padding: 20px;
  display: grid;
}

.logo-footer {
  width: 80px;
}

.footer-container a {
  color: #6e6e6e;
  text-decoration: none;
}

.footer-menu-item {
  color: #6e6e6e;
  margin-bottom: 10px;
}

.container {
  width: 700px;
  padding-top: 50px;
}

.save-card label {
  font-size: 1.1rem;
}

.gray-text {
  color: #75808a;
  margin-bottom: 10px;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.4rem;
}

.button-box {
  background-color: #fff;
  border-radius: 12px;
  grid-template-rows: auto;
  grid-template-columns: 1fr fit-content(70%);
  align-items: normal;
  row-gap: .5rem;
  margin-bottom: 20px;
  padding: .1rem 1rem;
  display: grid;
}

.button-box-name {
  font-size: 1.1rem;
  font-weight: 600;
}

.button-box.revolut {
  min-width: 0;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  align-items: normal;
  row-gap: .5rem;
  display: grid;
}

.card-button {
  height: 3rem;
  white-space: nowrap;
  width: 100%;
  color: #0666eb;
  cursor: pointer;
  background-color: #e6f0fd;
  border: none;
  border-radius: 16px;
  font-size: 18px;
  font-weight: 600;
}

.lds-grid {
  width: 80px;
  height: 80px;
  display: inline-block;
  position: relative;
}

.lds-grid div {
  width: 16px;
  height: 16px;
  background: #fff;
  border-radius: 50%;
  animation: 1.2s linear infinite lds-grid;
  position: absolute;
}

.lds-grid div:first-child {
  animation-delay: 0s;
  top: 8px;
  left: 8px;
}

.lds-grid div:nth-child(2) {
  animation-delay: -.4s;
  top: 8px;
  left: 32px;
}

.lds-grid div:nth-child(3) {
  animation-delay: -.8s;
  top: 8px;
  left: 56px;
}

.lds-grid div:nth-child(4) {
  animation-delay: -.4s;
  top: 32px;
  left: 8px;
}

.lds-grid div:nth-child(5) {
  animation-delay: -.8s;
  top: 32px;
  left: 32px;
}

.lds-grid div:nth-child(6) {
  animation-delay: -1.2s;
  top: 32px;
  left: 56px;
}

.lds-grid div:nth-child(7) {
  animation-delay: -.8s;
  top: 56px;
  left: 8px;
}

.lds-grid div:nth-child(8) {
  animation-delay: -1.2s;
  top: 56px;
  left: 32px;
}

.lds-grid div:nth-child(9) {
  animation-delay: -1.6s;
  top: 56px;
  left: 56px;
}

@keyframes lds-grid {
  0%, 100% {
    opacity: 1;
  }

  50% {
    opacity: .5;
  }
}

.spinner-screen {
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

input[type="checkbox"]:checked, input[type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

input[type="checkbox"]:checked + label, input[type="checkbox"]:not(:checked) + label {
  cursor: pointer;
  padding-left: 28px;
  line-height: 20px;
  display: inline-block;
  position: relative;
}

input[type="checkbox"]:checked + label:before, input[type="checkbox"]:not(:checked) + label:before {
  content: "";
  width: 18px;
  height: 18px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
}

input[type="checkbox"]:checked + label:after, input[type="checkbox"]:not(:checked) + label:after, input[type="radio"]:checked + label:after, input[type="radio"]:not(:checked) + label:after {
  content: "";
  -o-transition: all .2s ease;
  transition: all .2s;
  position: absolute;
}

input[type="checkbox"]:checked + label:after, input[type="checkbox"]:not(:checked) + label:after {
  width: 10px;
  height: 5px;
  border-bottom: 4px solid #016193;
  border-left: 4px solid #016193;
  border-radius: 1px;
  top: 4px;
  left: 3px;
  transform: rotate(-45deg);
}

input[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
}

input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

@media screen and (width <= 800px) {
  html {
    font-size: 18px;
  }

  .container {
    width: 94vw;
    padding-top: 50px;
  }

  .save-card label {
    font-size: 1rem;
  }

  .gray-text {
    font-size: .8rem;
    line-height: 1.2rem;
  }

  .button-box-name {
    font-size: 1rem;
    font-weight: 500;
  }
}

/*# sourceMappingURL=index.89f8a27e.css.map */
