html {
	scrollbar-gutter: stable;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Helvetica,
		Arial, Arimo, sans-serif;
	font-size: 16px;
	line-height: 1rem;
	min-width: 320px;
	background-color: rgb(247, 247, 247);
	color: rgb(25, 28, 31);
}

body {
	min-height: 100vh;
	background-color: rgb(247, 247, 247);
	margin: 0;
	position: relative;
}
.app {
	display: flex;
	justify-content: center;
	min-height: calc(100% - 326px);
}

footer {
	background-color: #d4d4d4;
	position: relative;
	bottom: 0;
	min-height: 100px;
	width: 100%;
	margin-top: 50px;
	padding-top: 20px;
	font-size: 14px;
}
.footer-copirigt {
	/* position: relative; */
	padding: 20px;
	background-color: #016193;
	color: #fff;
	/* width: 100%; */
	font-size: 12px;
	font-weight: 300;
	bottom: 0;
}
.footer-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
	grid-gap: 1rem;
	padding: 20px;
	min-height: 160px;
}
.logo-footer {
	width: 80px;
}
.footer-container a {
	text-decoration: none;
	color: rgb(110, 110, 110);
}
.footer-menu-item {
	margin-bottom: 10px;
	color: rgb(110, 110, 110);
}
.container {
	width: 700px;
	padding-top: 50px;
}

.save-card label {
	font-size: 1.1rem;
}
.gray-text {
	font-weight: 400;
	font-size: 0.875rem;
	color: rgb(117, 128, 138);
	margin-bottom: 10px;
	line-height: 1.4rem;
}

.button-box {
	background-color: rgb(255, 255, 255);
	border-radius: 12px;
	display: grid;
	align-items: normal;
	row-gap: 0.5rem;
	grid-template-columns: 1fr fit-content(70%);
	grid-template-rows: auto;
	padding: 0.1rem 1rem;
	margin-bottom: 20px;
}
.button-box-name {
	font-size: 1.1rem;
	font-weight: 600;
}

.button-box.revolut {
	display: grid;
	align-items: normal;
	row-gap: 0.5rem;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto;
	min-width: 0px;
}

.card-button {
	border: none;
	height: 3rem;
	border-radius: 16px;
	white-space: nowrap;
	width: 100%;
	background-color: rgb(230, 240, 253);
	color: rgb(6, 102, 235);
	font-size: 18px;
	cursor: pointer;
	font-weight: 600;
}

.lds-grid {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.lds-grid div {
	position: absolute;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	background: #fff;
	animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1) {
	top: 8px;
	left: 8px;
	animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
	top: 8px;
	left: 32px;
	animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
	top: 8px;
	left: 56px;
	animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
	top: 32px;
	left: 8px;
	animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
	top: 32px;
	left: 32px;
	animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
	top: 32px;
	left: 56px;
	animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
	top: 56px;
	left: 8px;
	animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
	top: 56px;
	left: 32px;
	animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
	top: 56px;
	left: 56px;
	animation-delay: -1.6s;
}
@keyframes lds-grid {
	0%,
	100% {
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
}
.spinner-screen {
	height: 100vh;
	width: 100vw;
	/* background-color: rgb(172, 161, 161); */
	background-color: white;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
}

input[type='checkbox']:checked,
input[type='checkbox']:not(:checked) {
	position: absolute;
	left: -9999px;
}

input[type='checkbox']:checked + label,
input[type='checkbox']:not(:checked) + label {
	display: inline-block;
	position: relative;
	padding-left: 28px;
	line-height: 20px;
	cursor: pointer;
}

input[type='checkbox']:checked + label:before,
input[type='checkbox']:not(:checked) + label:before {
	content: '';
	position: absolute;
	left: 0px;
	top: 0px;
	width: 18px;
	height: 18px;
	border: 1px solid #dddddd;
	background-color: #ffffff;
	border-radius: 5px;
}

input[type='checkbox']:checked + label:after,
input[type='checkbox']:not(:checked) + label:after,
input[type='radio']:checked + label:after,
input[type='radio']:not(:checked) + label:after {
	content: '';
	position: absolute;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

input[type='checkbox']:checked + label:after,
input[type='checkbox']:not(:checked) + label:after {
	left: 3px;
	top: 4px;
	width: 10px;
	height: 5px;
	border-radius: 1px;
	border-left: 4px solid #016193;
	border-bottom: 4px solid #016193;
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
}
input[type='checkbox']:not(:checked) + label:after {
	opacity: 0;
}

input[type='checkbox']:checked + label:after {
	opacity: 1;
}

@media screen and (max-width: 800px) {
	html {
		font-size: 18px;
	}
	.container {
		width: 94vw;
		padding-top: 50px;
	}
	.save-card label {
		font-size: 1rem;
	}
	.gray-text {
		font-size: 0.8rem;
		line-height: 1.2rem;
	}
	.button-box-name {
		font-size: 1rem;
		font-weight: 500;
	}
}
